<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Signups" subtitle="Manage new conventions coming onto the platform." />

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />

		<app-content-filters-tags v-model="filter.status" :options="filterStatus" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no sign-ups found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column />
				<app-table-column text="Convention" />
				<app-table-column text="User" />
				<app-table-column text="Sign-up Date" />
				<app-table-column text="Decision Date" />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onEditClick(item.id)" :success="item.status === $constants.admin.signup.status.approved" :danger="item.status === $constants.admin.signup.status.rejected" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-label :text="statusText[item.status]" :type="statusType[item.status]" />
					<app-table-cell-text :text="item.convention" />
					<app-table-cell-text :text="item.name" />
					<app-table-cell-text :text="item.date | localDate('MM/DD/YYYY h:mma')" />
					<app-table-cell-text :text="item.date_decision | localDate('MM/DD/YYYY h:mma')" v-if="item.date_decision" />
					<app-table-cell-text text="No decision yet." v-if="!item.date_decision" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			layout: '64px auto 240px 160px 160px',
			endpoint: 'organisations/signups',
			live: 'organisations/signups',
			edit: 'Organisations.Signups.Edit',
			filter: {
				status: [0]
			},
			statusText: {
				0: 'Pending',
				1: 'Approved',
				2: 'Rejected'
			},
			statusType: {
				0: 'yellow',
				1: 'green',
				2: 'red'
			},
			filterStatus: [
				{ value: 0, text: 'Pending' },
				{ value: 1, text: 'Approved', theme: 'green' },
				{ value: 2, text: 'Rejected', theme: 'red' }
			]
		}

	}

}

</script>

<style scoped>

</style>